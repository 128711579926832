// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/app/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/app/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-reports-js": () => import("/app/src/pages/authReports.js" /* webpackChunkName: "component---src-pages-auth-reports-js" */),
  "component---src-pages-contact-js": () => import("/app/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-viz-stem-sankey-js": () => import("/app/src/pages/data-viz/stem-sankey.js" /* webpackChunkName: "component---src-pages-data-viz-stem-sankey-js" */),
  "component---src-pages-degreesawarded-js": () => import("/app/src/pages/degreesawarded.js" /* webpackChunkName: "component---src-pages-degreesawarded-js" */),
  "component---src-pages-enrollment-majorsminors-js": () => import("/app/src/pages/enrollment-majorsminors.js" /* webpackChunkName: "component---src-pages-enrollment-majorsminors-js" */),
  "component---src-pages-index-js": () => import("/app/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interactive-fb-js": () => import("/app/src/pages/interactiveFB.js" /* webpackChunkName: "component---src-pages-interactive-fb-js" */),
  "component---src-pages-internal-admin-test-js": () => import("/app/src/pages/internalAdminTest.js" /* webpackChunkName: "component---src-pages-internal-admin-test-js" */),
  "component---src-pages-internal-enrollment-test-js": () => import("/app/src/pages/internalEnrollmentTest.js" /* webpackChunkName: "component---src-pages-internal-enrollment-test-js" */),
  "component---src-pages-internal-faculty-test-js": () => import("/app/src/pages/internalFacultyTest.js" /* webpackChunkName: "component---src-pages-internal-faculty-test-js" */),
  "component---src-pages-internal-reports-js": () => import("/app/src/pages/internalReports.js" /* webpackChunkName: "component---src-pages-internal-reports-js" */),
  "component---src-pages-internal-reports-test-js": () => import("/app/src/pages/internalReportsTest.js" /* webpackChunkName: "component---src-pages-internal-reports-test-js" */),
  "component---src-pages-internal-retention-test-js": () => import("/app/src/pages/internalRetentionTest.js" /* webpackChunkName: "component---src-pages-internal-retention-test-js" */),
  "component---src-pages-mission-js": () => import("/app/src/pages/mission.js" /* webpackChunkName: "component---src-pages-mission-js" */),
  "component---src-pages-osmreplacement-js": () => import("/app/src/pages/osmreplacement.js" /* webpackChunkName: "component---src-pages-osmreplacement-js" */),
  "component---src-pages-other-resources-js": () => import("/app/src/pages/other-resources.js" /* webpackChunkName: "component---src-pages-other-resources-js" */),
  "component---src-pages-parc-index-js": () => import("/app/src/pages/parc/index.js" /* webpackChunkName: "component---src-pages-parc-index-js" */),
  "component---src-pages-power-bi-reports-js": () => import("/app/src/pages/powerBiReports.js" /* webpackChunkName: "component---src-pages-power-bi-reports-js" */),
  "component---src-pages-public-reports-js": () => import("/app/src/pages/publicReports.js" /* webpackChunkName: "component---src-pages-public-reports-js" */),
  "component---src-pages-reports-index-js": () => import("/app/src/pages/reports/index.js" /* webpackChunkName: "component---src-pages-reports-index-js" */),
  "component---src-pages-requests-form-js": () => import("/app/src/pages/requests/form.js" /* webpackChunkName: "component---src-pages-requests-form-js" */),
  "component---src-pages-requests-index-js": () => import("/app/src/pages/requests/index.js" /* webpackChunkName: "component---src-pages-requests-index-js" */),
  "component---src-pages-requests-thank-js": () => import("/app/src/pages/requests/thank.js" /* webpackChunkName: "component---src-pages-requests-thank-js" */),
  "component---src-pages-resources-js": () => import("/app/src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-soi-index-js": () => import("/app/src/pages/soi/index.js" /* webpackChunkName: "component---src-pages-soi-index-js" */),
  "component---src-pages-staff-js": () => import("/app/src/pages/staff.js" /* webpackChunkName: "component---src-pages-staff-js" */)
}

